export const options = [
  {
    label: 'id',
    prop: 'Id',
    width: 80
  },
  {
    label: '用户id',
    prop: 'UserId',
    width: 80
  },
  {
    label: '用户钱包地址',
    prop: 'UserWalletAddress',
    width: 390
  },
  {
    label: '交易状态',
    prop: 'Status',
    width: 120
  },
  {
    label: '交易对',
    prop: 'TradingPair',
    width: 120
  },
  {
    label: '策略类型',
    prop: 'StrategyType',
    width: 140
  },
  {
    label: '创建时间',
    prop: 'CreatedAt'
  },
  {
    label: 'action',
    prop: 'action',
    width: 160,
    hide: true
  }
]
